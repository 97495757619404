.custom-swiper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 2px;

  .swiper-pagination {
    position: relative;
    margin-top: 64px;
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -24px;

    .swiper-pagination-bullet {
      background: #ffffff;
      opacity: 1;
      max-width: 36px;
      height: 1px;
      border-radius: 0;
      flex: 1;
      margin: 0px 1px;

      &.swiper-pagination-bullet-active {
        background: #ff00ff;
        height: 3px;
      }
    }

    @media (max-width: 1200px) {
      margin-top: 48px;
      margin-left: 0;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 32px;
    height: 32px;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    padding: 8px;
    position: relative;
    margin-top: 48px;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;

    &::after {
      font-size: 20px;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .swiper-button-next {
    left: 550px;
  }
}
