.mobile-menu {
  .bm-overlay {
    top: 0;
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  .bm-menu-wrap {
    top: 0;
    z-index: 9999 !important;
  }

  .bm-burger-button {
    position: fixed;
    width: 22px;
    height: 18px;
    right: 21px;
    top: 17px;
    z-index: 9999 !important;
  }

  .bm-burger-bars {
    background-color: #ffffff;
    height: 2px !important;
  }

  .bm-morph-shape svg path {
    fill: #0076ff;
  }

  .bm-cross-button {
    top: 12px !important;
    right: 12px !important;

    .bm-cross {
      background-color: #ffffff;
      width: 2px !important;
      height: 18px !important;
    }
  }

  .bm-menu {
    width: 100%;
  }
}
