.achieve-tooltip {
  padding: 0 10px;

  .MuiTooltip-tooltip {
    border-radius: 16px;
    padding: 8px 24px;

    ul {
      margin-left: 32px;
    }
  }

  &.large {
    .MuiTooltip-tooltip {
      max-width: 550px;
    }
  }

  &.top-padding {
    .MuiTooltip-tooltip {
      padding: 32px 24px 24px;
    }
  }

  &.dark {
    .MuiTooltip-tooltip {
      background-color: #1E1E1E;
    }

    .MuiTooltip-arrow::before {
      background-color: #1E1E1E;
    }
  }
}
