/* insert custom global style */

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #000000;
  font-family: 'Roboto', 'InterMedium', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
  }
}

button {
  text-transform: none;
}
